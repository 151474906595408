

















































































































































































import { Component, Vue } from 'vue-property-decorator';
// import { Store } from 'vuex';
// import { readUserProfile } from '@/store/main/getters';
import { readUserItems } from '@/store/main/getters';
import { dispatchGetUserItemsPending } from '@/store/main/actions';
import { readItemCreateError } from '@/store/main/getters';
import { dispatchItemCreate } from '@/store/main/actions';
import { readConstants } from '@/store/main/getters';
import { readUserProfile } from '@/store/main/getters';
import { dispatchGetConstants, dispatchGetUserProfile } from '@/store/main/actions';
import { readIsLoggedIn } from '@/store/main/getters';


@Component
export default class Dashboard extends Vue {
  // my edit
  // public plans = ['USDⓢ 鯨魚方案', 'USDⓢ 小資方案', 'USDⓢ 穩定方案', 'USDⓢ 高利率方案'];
  // public plansCode = [ 'USDT_active', 'USDT_fixed', 'USDT_stable', 'USDT_high'];

  public plans = ['USDⓢ 朝日方案', 'USDⓢ 流星方案', 'ETH 穩定方案'];
  public plansCode = ['USDT_sun', 'USDT_meteor', 'ETH_stable'];

  public tokenTypes = ['USDT', 'USDC'];
  public blockchainTypes = ['TRC20', 'Polygon'];

  // public plans_less = ['USDⓢ 小資方案', 'USDⓢ 穩定方案', 'USDⓢ 高利率方案'];
  // public plansCode_less = ['USDT_fixed', 'USDT_stable', 'USDT_high'];

  public title: string = 'USDⓢ 朝日方案';
  public tokens: number = 0;
  public walletHoldings: number = 0;  
  public duration: string = '';
  public code_promotion: string = '';
  public contract: boolean = false;
  public agreements: boolean = false;
  public nfa: boolean = false;
  public tokenType: string = 'USDT';
  public tokenImgURL: string = require('@/assets/images/token_USDT.png');
  public blockchainType: string = 'TRC20';
  public placeholder: string = '金額';
  public agreementsText: string = '我知道此方案不保本，操作策略有可能導致本金損失，且加密貨幣本身存在較高的系統性風險，有可能帶來大量虧損';
  public code_promotion_display_text: string = "";

  public valid = false;

  public amountMin = 1000;
  public amountMax = 30000;
  public durationMin = 14;
  public durationMax = 180;

  public titleRules() {
    return [(v) => !!v || '請選擇',
    ];
  };

  get userProfile() {
    return readUserProfile(this.$store);
  }

  get loggedIn() {
    return readIsLoggedIn(this.$store);
  }

  public useMaxToken() {
    this.tokens = this.walletHoldings;
  }

  public contractURL() {
    // if (this.title == "USDⓢ 高利率方案") {
    //   return "https://drive.google.com/file/d/1SA4Hy2vp1JaJlbbQZq0ZujCsMsZUECH_/view?usp=sharing";
    // } else { // USDT 穩定方案
      return "https://drive.google.com/file/d/1QF_u3Yw4Rl9qW5JJMCllEXlq57yDt8fM/view?usp=share_link";
    // }
  }

  public amountRules() {
    let minStr;
    let maxStr;

    if (this.tokenType === 'TWD') {
      minStr = '金額需大於 NT$' + this.amountMin;
      maxStr = '金額需小於 NT$' + this.amountMax;
    } else {
      minStr = '金額需大於 ' + this.amountMin + ' ' + this.tokenType;
      // maxStr = '金額需小於 ' + this.amountMax + ' ' + this.tokenType;
      maxStr = "錢包餘額不足";
    }

    return [
      (v) => !!v || '請決定金額',
      (v) => ( v && v <= this.amountMax ) || maxStr,
      (v) => ( v && v >= this.amountMin ) || minStr,
    ];
  }

  public durationRules() {
    return [
      (v) => !!v || '請決定約期',
      (v) => ( v && v >= this.durationMin ) || '約期需大於 ' + this.durationMin + ' 天',
      (v) => ( v && v <= this.durationMax ) || '約期需小於 ' + this.durationMax + ' 天',
    ];
  }

  public promotionCodeRules() {
    if (this.userProfile) {
      let used_promotion_codes: string[] = [];
      if (this.userProfile.used_promotion_codes) {
        used_promotion_codes = this.userProfile.used_promotion_codes.split(',');
      } else {
        used_promotion_codes = [];
      }

      return [
        (v) => ( !used_promotion_codes.includes(v) ) || '該優惠碼已被使用過',
      ]
    }
  }

  public contractRules() {
    return [v => !!v || '請閱讀並同意定型化契約書']
  }

  public agreementRules() {
    return [v => !!v || '請勾選並同意']
  }

  public get itemCreateError() {
    return readItemCreateError(this.$store);
  }

  public changedValue(value) {
    if (this.title == 'USDⓢ 鯨魚方案') {
      const constants = readConstants(this.$store);
      // if (constants) {
      //   this.amountMax = 100000 - constants.active_plan_AUM;
      // }

      // if (this.amountMax > 30000) {
      //   this.amountMax = 30000;
      // }

      this.placeholder = 'USDⓢ 數量';
      this.amountMin = 500;
      // this.amountMax = 30000;
      this.duration = '90';
      this.durationMin = 90;
      this.durationMax = 90;
      this.tokenTypes = ['USDT', 'USDC', 'BUSD'];
      this.agreementsText = '我知道此方案不保本，操作策略有可能導致本金損失，且加密貨幣本身存在較高的系統性風險，有可能帶來大量虧損；且策略虧損最大可能達 20%';
    } else if (this.title == 'USDⓢ 小資方案') {
      this.placeholder = 'USDⓢ 數量';
      this.amountMin = 100;
      // this.amountMax = 100000;
      this.duration = '14';
      this.durationMin = 14;
      this.durationMax = 365;
      this.tokenTypes = ['USDT', 'USDC', 'BUSD'];
      this.agreementsText = '我知道此方案不保本，操作策略有可能導致本金損失，且加密貨幣本身存在較高的系統性風險，有可能帶來大量虧損';
    } else if (this.title == 'USDⓢ 穩定方案') {
      this.placeholder = 'USDⓢ 數量';
      this.amountMin = 1000;
      // this.amountMax = 100000;
      this.duration = '30';
      this.durationMin = 30;
      this.durationMax = 365;
      this.tokenTypes = ['USDT', 'USDC', 'BUSD'];
      this.agreementsText = '我知道此方案不保本，操作策略有可能導致本金損失，且加密貨幣本身存在較高的系統性風險，有可能帶來大量虧損';
    } else if (this.title == 'USDⓢ 高利率方案') {
      this.placeholder = 'USDⓢ 數量';
      this.amountMin = 10000;
      // this.amountMax = 100000;
      this.duration = '90';
      this.durationMin = 90;
      this.durationMax = 365;
      this.tokenTypes = ['USDT', 'USDC', 'BUSD'];
      this.agreementsText = '我知道此方案不保本，操作策略有可能導致本金損失，且加密貨幣本身存在較高的系統性風險，有可能帶來大量虧損；且策略虧損最大可能達 10%';
    } else if (this.title == 'USDD 挖礦方案') {
      this.placeholder = 'USDⓢ 數量';
      this.amountMin = 500;
      // this.amountMax = 100000;
      this.duration = '30';
      this.durationMin = 30;
      this.durationMax = 365;
      this.tokenTypes = ['USDT', 'USDC', 'BUSD'];
      this.agreementsText = '我知道此方案不保本，操作策略有可能導致本金損失，且加密貨幣本身存在較高的系統性風險，有可能帶來大量虧損；且使用 USDD 算法穩定幣有脫鉤風險。';
    } else if (this.title == 'BTC 穩定方案') {
      this.placeholder = 'BTC 數量';
      this.amountMin = 0.01;
      // this.amountMax = 2;
      this.duration = '30';
      this.durationMin = 30;
      this.durationMax = 365;
      this.tokenTypes = ['BTC'];
      this.agreementsText = '我知道此方案不保本，操作策略有可能導致本金損失，且加密貨幣本身存在較高的系統性風險，有可能帶來大量虧損';
    } else if (this.title == 'ETH 穩定方案') {
      this.placeholder = 'ETH 數量';
      this.amountMin = 1;
      // this.amountMax = 10;
      this.duration = '30';
      this.durationMin = 30;
      this.durationMax = 365;
      this.tokenTypes = ['ETH'];
      this.agreementsText = '我知道此方案不保本，操作策略有可能導致本金損失，且加密貨幣本身存在較高的系統性風險，有可能帶來大量虧損';
    } else if (this.title == 'BNB 穩定方案') {
      this.placeholder = 'BNB 數量';
      this.amountMin = 1;
      // this.amountMax = 100;
      this.duration = '30';
      this.durationMin = 30;
      this.durationMax = 365;
      this.tokenTypes = ['BNB'];
      this.agreementsText = '我知道此方案不保本，操作策略有可能導致本金損失，且加密貨幣本身存在較高的系統性風險，有可能帶來大量虧損';
    } else if (this.title == 'USDⓢ 朝日方案') {
      this.placeholder = 'USDⓢ 數量';
      this.amountMin = 100;
      // this.amountMax = 100000;
      this.duration = '7';
      this.durationMin = 7;
      this.durationMax = 365;
      this.tokenTypes = ['USDT', 'USDC', 'BUSD'];
      this.agreementsText = '我知道此方案不保本，操作策略有可能導致本金損失，且加密貨幣本身存在較高的系統性風險，有可能帶來大量虧損';
    } else if (this.title == 'USDⓢ 流星方案') {
      this.placeholder = 'USDⓢ 數量';
      this.amountMin = 1000;
      // this.amountMax = 100000;
      this.duration = '30';
      this.durationMin = 30;
      this.durationMax = 365;
      this.tokenTypes = ['USDT', 'USDC', 'BUSD'];
      this.agreementsText = '我知道此方案不保本，操作策略有可能導致本金損失，且加密貨幣本身存在較高的系統性風險，有可能帶來大量虧損';
    } else {
      this.duration = '87';
      this.amountMin = 0;
      // this.amountMax = 0;
    }

    // console.log(this.amountMin)
    // console.log(this.amountMax)

    if (this.tokenTypes.includes(this.tokenType) == false) {
      this.tokenType = this.tokenTypes[0];
    }

    // change supported blockchain for each token, and update user wallet holdings
    if (this.tokenType == 'USDT') {
      this.blockchainTypes = ['TRC20', 'BEP20', 'Polygon'];
      this.tokenImgURL = require('@/assets/images/token_USDT.png');
      if (this.userProfile) {
        this.walletHoldings = this.userProfile.wallet_USDT;
        this.amountMax = this.userProfile.wallet_USDT;
      }
    } else if (this.tokenType == 'USDC') {
      this.blockchainTypes = ['BEP20', 'Polygon'];
      this.tokenImgURL = require('@/assets/images/token_USDC.png');
      if (this.userProfile) {
        this.walletHoldings = this.userProfile.wallet_USDC;
        this.amountMax = this.userProfile.wallet_USDC;
      }
    } else if (this.tokenType == 'BUSD') {
      this.blockchainTypes = ['BEP20'];
      this.tokenImgURL = require('@/assets/images/token_BUSD.png');
      if (this.userProfile) {
        this.walletHoldings = this.userProfile.wallet_BUSD;
        this.amountMax = this.userProfile.wallet_BUSD;
      }
    } else if (this.tokenType == 'BTC') {
      this.blockchainTypes = ['BEP20'];
      this.tokenImgURL = require('@/assets/images/token_BTC.png');
      if (this.userProfile) {
        this.walletHoldings = this.userProfile.wallet_BTC;
        this.amountMax = this.userProfile.wallet_BTC;
      }
    } else if (this.tokenType == 'ETH') {
      this.blockchainTypes = ['ERC20', 'BEP20'];
      this.tokenImgURL = require('@/assets/images/token_ETH.png');
      if (this.userProfile) {
        this.walletHoldings = this.userProfile.wallet_ETH;
        this.amountMax = this.userProfile.wallet_ETH;
      }
    } else if (this.tokenType == 'BNB') {
      this.blockchainTypes = ['BEP20'];
      this.tokenImgURL = require('@/assets/images/token_BNB.png');
      if (this.userProfile) {
        this.walletHoldings = this.userProfile.wallet_BNB;
        this.amountMax = this.userProfile.wallet_BNB;
      }
    } 

    if (this.blockchainTypes.includes(this.blockchainType) == false) {
      this.blockchainType = this.blockchainTypes[0];
    }

    // if (this.code_promotion == 'DAO+66') {
    //   this.code_promotion_display_text = "使用優惠碼，1000 USDT 內利息 +66%，持續一個月";
    // } else {
    //   this.code_promotion_display_text = "";
    // }

    // console.log(this.title);
    // console.log(this.tokenType);
    // console.log(this.blockchainType);
    // console.log(this.code_promotion);
    // console.log("---");
  }

  public async submit() {
    if ((this.$refs.form as any).validate()) {
      let submitTitle; // string
      let submitCoin; // string, e.g. 'USDT:3000'
      let submitDuration; // int
      let submitAmount;
      let submitTokenType;
      let submitBlockchainType;

      // parse title and coin
      if (this.title === this.plans[0]) {
          submitTitle = this.plansCode[0]; 
          // submitCoin = 'USDT:' + this.tokens;
          submitAmount = this.tokens;
      } else if (this.title === this.plans[1]) {
          submitTitle = this.plansCode[1]; 
          // submitCoin = 'USDT:' + this.tokens;
          submitAmount = this.tokens;
      } else if (this.title === this.plans[2]) {
          submitTitle = this.plansCode[2]; 
          // submitCoin = 'USDT:' + this.tokens;
          submitAmount = this.tokens;
      } else if (this.title === this.plans[3]) {
          submitTitle = this.plansCode[3]; 
          // submitCoin = 'USDT:' + this.tokens;
          submitAmount = this.tokens;
      } else if (this.title === this.plans[4]) {
          submitTitle = this.plansCode[4]; 
          // submitCoin = 'USDT:' + this.tokens;
          submitAmount = this.tokens;
      } else if (this.title === this.plans[5]) {
          submitTitle = this.plansCode[5]; 
          // submitCoin = 'USDT:' + this.tokens;
          submitAmount = this.tokens;
      }  else if (this.title === this.plans[6]) {
          submitTitle = this.plansCode[6]; 
          // submitCoin = 'USDT:' + this.tokens;
          submitAmount = this.tokens;
      }  else {
          submitTitle = 'undefined_plan_title';
          // submitCoin = 'UNK:' + this.tokens;
          submitAmount = this.tokens;
      }

      console.log(this.title);
      console.log(submitTitle);
      console.log(submitAmount);
      console.log(this.code_promotion);
      console.log("---");

      // parse duration
      submitDuration = this.duration.replace(' 天', '');
      submitDuration = parseInt(submitDuration, 10);

      submitTokenType = this.tokenType;
      submitBlockchainType = this.blockchainType;

      // this.dialog = true;
      dispatchItemCreate(this.$store, {title: submitTitle, initial_amount: submitAmount, token_type: submitTokenType, blockchain_type: submitBlockchainType, duration: submitDuration, code_promotion: this.code_promotion});
      
    }
  }

  // parse URL query
  // from /purchase?plan=usdt_stable
  created() {
    let params = (new URL(document.location.href).searchParams);
    let planTitle = params.get("plan");

    for (let i = 0; i < this.plans.length; i++) {
      if (planTitle === this.plansCode[i].toLowerCase()) {
        this.title = this.plans[i];
        break
      }
    }

    this.changedValue(this.title);

    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.checkVersion();
  }

  get userItemsPending() {
    return readUserItems(this.$store);
  }

  async mounted() {
    // await dispatchGetUserItemsPending(this.$store);
    await dispatchGetConstants(this.$store);
    await dispatchGetUserProfile(this.$store);
  }

  // update version
  checkVersion() {
    const constants = readConstants(this.$store);

    let onlineVersion = "none";
    if (constants) {
      onlineVersion = constants.version;
    }

    let localVersion = localStorage.getItem('vers');
    let updateSignal = localStorage.getItem('upt');

    // check if we need to fresh the browser
    if (onlineVersion != "none" && localVersion != onlineVersion) { // detect newer online version
      localStorage.setItem('vers', onlineVersion);
      window.location.reload();
    } else if (updateSignal == 'refresh') { // detect refresh signal
      localStorage.setItem('upt', 'no');
      window.location.reload();
    }
  }

  // adaptive screen size
  public isMobile = false;

  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize);
    }
  }

  onResize() {
    this.isMobile = window.innerWidth < 960;
  }
}

